import React from 'react';
import H1 from '../../components/H1';
import Tagline from '../../components/Tagline';

const Title = (props) => {
    const { title, tagline } = props;
    return (
        <>
            <H1> { title } </H1>
            <Tagline> { tagline } </Tagline>
        </>
    );
}
export default Title;