import styled from 'styled-components';

const BonusListIcons = styled.div`
    @media (max-width: 700px) {
        flex-direction: column;
    }
    display: flex;
    flex-wrap: wrap;
    color: rgb(82, 168, 235);
`;

export default BonusListIcons;