import React from 'react';
import styled from 'styled-components';

const I = styled.i`
    margin-right: 10px;
    width: 28px;
    font-size: 28px;
    text-align: center;
    color: rgb(82, 168, 235);
`;

const Icon = ({ icon, title }) => {
    return <I className={ icon } title={title}></I>;
};

export default Icon;