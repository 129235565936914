import React from 'react';
import {
    BonusTitle,
    BonusContainer,
    BonusItem,
    BonusSection
} from '../../components/BonusElements';
import ShowOnMobile from '../../components/ShowOnMobile';
import Seperator from '../../components/Seperator';

const Bonus = ({ items }) => {
    return (
        <BonusContainer>
            {items.map((item, index) => {
                return (
                    <>
                    <BonusItem key={item.title}>
                        <BonusTitle>{ item.title }</BonusTitle>
                        <BonusSection { ...item } />
                    </BonusItem>
                    {function(index){
                        if (index < items.length - 1) {
                            return (
                                <ShowOnMobile>
                                    <div style={{marginBottom: '30px'}}></div>
                                    <Seperator />
                                </ShowOnMobile>
                            );
                        }
                        return '';
                    }(index)}
                    </>
                );
            })}
        </BonusContainer>
    )
}

export default Bonus;