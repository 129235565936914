import React from 'react';
import styled from 'styled-components';
import Quote from '../../components/Quote';
import QuoteIcon from '../../components/QuoteIcon';

const QuoteContain = styled.div`
    margin-bottom: 30px;
    padding: 20px 30px;
    display: flex;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    align-items: center;
    justify-content: center;
`;

const QuoteContainer = ({ quote }) => {
    return (
        <QuoteContain>
            <QuoteIcon />
            <Quote> { quote } </Quote>
        </QuoteContain>
    );
};

export default QuoteContainer;