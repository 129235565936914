import styled from 'styled-components';

const Date = styled.div`
    margin: 0px;
    font-size: 12px;
    font-weight: light;
    text-size-adjust: 100%
    color: rgb(194, 189, 180);
`

export default Date;