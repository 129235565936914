import React from 'react';
import styled from 'styled-components';

const C = styled.i`
    color: rgb(82, 170, 235);
    margin-right: 2px;
`;

const Circle = ({ icon }) => {
    return <C className={icon} />
}

export default Circle;