import styled from 'styled-components';

const H3 = styled.h3`
    margin: 0px;
    font-weight: lighter;
    text-size-adjust: 100%;
    font-size: 19px;
    color: rgb(82, 168, 235);
`

export default H3;