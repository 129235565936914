import styled from 'styled-components';

const ListItem = styled.li`
    position: relative;
    padding-left: 12px;
    margin-bottom: 3px;

    &::before {
        background-image: initial;
        background-color: rgb(61, 64, 67);
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        top: 7px;
        left: 0;
    }
`

export default ListItem;