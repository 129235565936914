import styled from 'styled-components';

const H1 = styled.h1`
    margin: 0px;
    text-align: center;
    font-size: 36px;
    font-weight: lighter;
    text-size-adjust: 100%;
    color: rgb(82, 168, 235);
`

export default H1;