import styled from 'styled-components';

const H2 = styled.h2`
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 700;
    text-size-adjust: 100%;
    color: rgb(214, 211, 205);
`

export default H2;