import React from 'react';
import styled from 'styled-components';

const BonusCert = styled.i`
        font-size: 21px;
        color: rgb(82, 170, 235);
`;

const BonusCertificate = ({ icon, title }) => {
    return <BonusCert className={ icon } title={title}></BonusCert>;
};

export default BonusCertificate;