import styled from 'styled-components';
import React from 'react';


const Div = styled.div`
    @media (max-width: 700px) {
        display: block;
    }
    display: none;
`;

const ShowOnMobile = ({ children }) => {
    return (
        <Div>
            {children}
        </Div>
    )
}

export default ShowOnMobile;