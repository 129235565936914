import styled from 'styled-components';

const Body = styled.div`
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`;

export default Body;