import styled from 'styled-components';

const List = styled.ul`
    font-size: 14px;
    padding: 0;
    margin: 10px 0 0 0;
    list-style-type: none;
    font-family: 'Open Sans', sans-serif;
    text-size-adjust: 100%;
    color: rgb(195, 191, 182);
`

export default List;