import styled from 'styled-components';

const H4 = styled.h4`
    margin: 0px;
    font-size: 17px;
    font-weight: 700;
    text-size-adjust: 100%;
    color: rgb(208, 204, 197);
`

export default H4;