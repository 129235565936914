import React from 'react';
import styled from 'styled-components';

const QuoteI = styled.i`
    border: 1px solid #1E88E5;
    border-radius: 50%;
    font-size: 44px;
    margin-right: 20px;
    flex: 0 0 70px;
    height: 70px;
    width: 70px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: rgb(82, 168, 235);
`;


const QuoteIcon = () => {
    return <QuoteI className="fa fa-quote-left"></QuoteI>;
};

export default QuoteIcon;