import React from 'react';
import styled from 'styled-components';
import CTA from '../../components/CTA';

const Row = styled.div`
    @media (max-width: 600px) {
        flex-direction: column;
    }

    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
`;

const CTAs = (props) => {
    const { CTAs } = props;
    return (
        <Row> 
            {CTAs.map((cta) => {
                return <CTA key={ cta.value } { ...cta } />;
            })}
        </Row>
    );
}
export default CTAs;