import React from 'react';
import styled from 'styled-components';
import MutedAnchor from '../MutedAnchor';
import Text from '../Text';
import Icon from '../Icon';

const CtaContainer = styled.div`
    @media (max-width: 700px) {
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0px;
        }
    }

    margin-bottom: 8px;
    flex-basis: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 320px;
`;

const CTA = ({ displayValue, value, icon }) => {
    return (
        <CtaContainer>
            <Icon icon={ icon } />
            <Text> <MutedAnchor href={ value } target="_blank" > { displayValue } </MutedAnchor> </Text>
        </CtaContainer>
    );
}; 

export default CTA;