import React from "react";
import styled from "styled-components";

const Box = styled.div`
  @media (max-width: 700px) {
    margin: 0px !important;
    flex-basis: 1;
    margin: 0px;
    padding: 0px;
    background-color: inherit;
  }

  float: right;
  background-color: rgb(27, 29, 30);
  padding: 30px;
  margin: 0 0 30px 30px;
  flex-basis: 26%;
  align-self: start;
`;

const InfoBox = props => {
  return <Box>{props.children}</Box>;
};

export default InfoBox;
