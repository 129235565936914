import React from 'react';
import List from '../../components/List';
import ListItem from '../../components/ListItem';
import Date from '../../components/Date';
import H2 from '../../components/H2';
import H3 from '../../components/H3';
import H4 from '../../components/H4';
import Section from '../../components/Section';

const InfoSection = ({ title, content }) => {
    return (
        <>
            {showTitle(title)}
            {showContent(content)}
        </>
    );
}

export default InfoSection;

function showTitle(title) {
    if (title) {
        return <H2>{ title }</H2>
    } else {
        return;
    }
}

function showContent(content) {
    return content.map(item => {
        return (
            <Section key={item.title}>
                <Date>
                    { item.date }
                </Date>
                <H3>
                    { item.title }
                </H3>
                <H4>
                    { item.tagline }
                </H4>
                <List>
                    {showPoints(item)}
                </List>
            </Section>
        );
    });
}

function showPoints(item) {
    return item.points.map((point, index) => {
        return (
            <ListItem key={`${item.title}${index}`}>
                { point }
            </ListItem>
        );
    });
}