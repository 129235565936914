import React from 'react';
import {
    BonusListItem,
    BonusIcon,
    BonusCertificate,
    BonusSkillBar
} from '../index';

function BonusSectionItem({ item, type }) {
    let answer;

    switch (type) {
        case 'bar':
            answer = (
                <BonusListItem key={`${type}_${item.value}`}>
                    {item.displayValue}
                    <BonusSkillBar value={ item.value } />
                </BonusListItem>
            );
            break;
        case 'icon':
            answer = <BonusIcon icon={item.value} title={item.displayValue} />;
            break;
        default:
            answer = (
                <BonusListItem key={`${type}_${item.value}`}>
                    {item.displayValue}
                    <BonusCertificate icon={item.icon} title={item.value}/>
                </BonusListItem>
            );
            break;
    }

    return answer;
}

export default BonusSectionItem;