module.exports = {
  title: "Daniel Cudney",
  tagline: "Digital Consultant",
  CTAs: [
    {
      displayValue: "DanielCudney",
      value: "https://www.linkedin.com/in/DanielCudney",
      icon: "fab fa-linkedin"
    },
    {
      displayValue: "San Diego",
      value:
        "https://www.google.com/maps/place/San+Diego,+CA/@32.8574486,-117.2621376,14z",
      icon: "fas fa-globe-americas"
    },
    {
      displayValue: "Dman89",
      value: "https://github.com/Dman89",
      icon: "fab fa-github-square"
    }
  ],
  quote:
    "“There is little success where there is little laughter.” - Andrew Carnegie",
  sections: {
    experience: {
      title: "Experience",
      content: [
        {
          date: "2018 - Present",
          title: "Innovation Architect",
          tagline: "Nuvolo, Inc.",
          points: [
            "Partner with product managers on strategic planning, roadmap development, and feature prioritization for cloud-based enterprise workplace management software.",
            "Work directly with clients to understand their business requirements, translate their needs into platform specifications, and provide technical guidance and support to drive platform adoption and utilization.",
            "Designed, developed, and implemented an integrated workplace management system (IWMS) for ServiceNow to optimize the management of 100+ buildings with 10k+ spaces with 10k+ employees.",
            "Built and maintained KPI reporting systems to enable healthcare clients to track and analyze millions of clinical assets, ensuring FDA compliance and increasing operational efficiency.",
            "Decreased system load times by 80% and improved engineering efficiency by proactively identifying and fixing critical bugs, utilizing a cache-first approach, and ensuring code reusability where possible.",
            "Designed, developed, and launched Nuvolo’s HR integration with ServiceNow’s self-service portal to bring together Facilities, HR, and IT in a single platform, enabling clients to maximize their investment.",
            "Led a team of 3 developers to build employee request features and a manager UI to streamline the self-service portal, enable task tracking, and execute administrative changes."
          ]
        },
        {
          date: "2017 - 2018",
          title: "Software Engineer",
          tagline: "NewRocket, Inc.",
          points: [
            `Designed features to allow admins to validate discovered content, generate the necessary tasks for business processes, and view a dashboard display of the current status of all tasks in a domain separate instance, improving infrastructure management at scale and creating a reusable product for monetization.`,
            `Built a domain separation feature for admins to separate data, processes, and administrative tasks into groupings and enable access control to increase information security and data privacy.`,
            `Developed a platform tour system to educate new users and drive product adoption and utilization.`,
            `Worked directly with Fortune 500 clients to design self-service portals and improve system usability.`,
            `Collaborated with a team of 6 engineers to decrease service portal development time from 6 to 2 weeks.`,
            `Mentored junior developers on key coding skills to improve overall code quality and engineering efficiency`
          ]
        }
      ]
    },
    education: {
      title: "Education",
      content: [
        {
          date: "2019",
          title: "Certified Application Developer",
          tagline: "ServiceNow",
          points: []
        },
        {
          date: "2019",
          title: "Certified System Administrator",
          tagline: "ServiceNow",
          points: []
        }
      ]
    },
    volunteering: {
      title: "Volunteering",
      content: [
        {
          date: "2016 - 2018",
          title: "Meetup Organizer",
          tagline: "FreeCodeCamp",
          points: []
        },
        {
          date: "2015",
          title: "Volunteer",
          tagline: "Habitat for Humanity",
          points: []
        }
      ]
    },
    experienceExtra: {
      title: "",
      content: []
    }
  },
  bonus: [
    {
      title: "Certifications",
      type: "text",
      items: [
        {
          displayValue: "Application Developer",
          value: "ServiceNow Certified Application Developer (CAD)",
          icon: "fas fa-terminal"
        },
        {
          displayValue: "System Administrator",
          value: "ServiceNow System Administrator (CSA)",
          icon: "fas fa-users-cog"
        }
      ]
    },
    {
      title: "Skills",
      type: "bar",
      items: [
        {
          displayValue: "JavaScript",
          value: 5
        },
        {
          displayValue: "ServiceNow",
          value: 5
        },
        {
          displayValue: "React & Redux",
          value: 4
        },
        {
          displayValue: "UX",
          value: 3
        }
      ]
    },
    {
      title: "Interests",
      type: "icon",
      items: [
        {
          value: "fas fa-user-astronaut",
          displayValue: "Enjoying the night's sky. (Are we alone?)"
        },
        {
          value: "fas fa-globe-europe",
          displayValue:
            "Exploring planet earth and meeting humans. (When was your last adventure?)"
        },
        {
          value: "fas fa-umbrella-beach",
          displayValue:
            "Enjoying the beach in sunny San Diego. (Do you go with the ebb and flow?)"
        }
      ]
    }
  ]
};
