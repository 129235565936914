import styled from 'styled-components';

const Tagline = styled.div`
    margin-bottom: 26px;
    font-size: 26px;
    text-align: center;
    text-size-adjust: 100%;
    color: rgb(214, 211, 205);
`

export default Tagline;