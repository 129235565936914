import styled from 'styled-components';

const BonusListItem = styled.li`
    color: rgb(195, 191, 182);
    margin-bottom: 11px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 900px) {
        flex-direction: column;
        & > * {
            margin-top: 5px;
        }
    }
`;


export default BonusListItem;