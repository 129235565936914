import styled from "styled-components";

const BonusI = styled.i`
  @media (max-width: 700px) {
    flex-direction: column;
    text-align: left !important;
    flex: 1;
    width: inherit;
    margin: 15px;
  }

  font-size: 42px;
  flex: 0 0 33.33%;
  margin-bottom: 24px;
  margin-right: 0px;
  font-stretch: 100%;
  width: 33.33%;

  &:nth-child(3n) {
    text-align: right;
  }

  &:nth-child(3n + 1) {
    text-align: left;
  }

  &:nth-child(3n + 2) {
    text-align: center;
  }
`;

export default BonusI;
