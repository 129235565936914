import React from 'react';
import Bonus from './containers/Bonus';
import Title from './containers/Title';
import CTAs from './containers/CTAs';
import QuoteContainer from './containers/QuoteContainer';
import InfoSection from './containers/InfoSection';
import InfoBox from './components/InfoBox';
import Body from './components/Body';
import Seperator from './components/Seperator';
import ShowOnMobile from './components/ShowOnMobile';
import data from './data/cv.data';
import './styles/fontawesome.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Title { ...data } />
      <CTAs { ...data } />
      <QuoteContainer { ...data } />
      <Body>
        <div>
          <InfoSection { ...data.sections.experience } />
        </div>
        <ShowOnMobile>
          <Seperator />
        </ShowOnMobile>
        <InfoBox>
          <InfoSection { ...data.sections.education } />
          <InfoSection { ...data.sections.volunteering } />
        </InfoBox>
      </Body>
      <InfoSection { ...data.sections.experienceExtra } />
      <Seperator />
      <Bonus items={ data.bonus } />
    </div>
  );
}

export default App;
