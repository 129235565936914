import styled from 'styled-components';

const BonusItem = styled.div`
    flex: 0 0 33.33%;
    flex: 1;
    padding: 0 25px;

    &:first-child {
        padding-left: 0;
        padding-right: 50px;
    }

    &:last-child {
        padding-right: 0;
        padding-left: 50px;
    }

    @media (max-width: 700px) {
        padding: 0px !important;
    }
`;

export default BonusItem;