import React from 'react';
import {
    BonusList,
    BonusListIcons,
    BonusSectionItem
} from '../index';

function BonusSection({items, type}) {
    let answer;
    const children = items.map((item, index) => {
        return <BonusSectionItem  key={`${item.title}_${index}`} item={item} type={type} />;
    });

    switch (type) {
        case 'icon':
            answer = (
                <BonusListIcons>
                    {children}
                </BonusListIcons>
            );
            break;
        default:
            answer = (
                <BonusList>
                    {children}
                </BonusList>
            );
            break;
    }
    return answer;
}
export default BonusSection;