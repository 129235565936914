import React from 'react';
import Circle from './Circle';

function BonusSkillBar({ value }) {
    const maxValue = 5;
    const emptyCircles = maxValue - value;
    const answer = [];
    for (var x = 0; x < value; x++) {
        answer.push('fas fa-circle');
    }
    for (var y = 0; y < emptyCircles; y++) {
        answer.push('far fa-circle');
    }
    return <div>
        {
            answer.map((circle, index) => {
                return <Circle key={`${circle}_${index}`} icon={circle} />
            })
        }
    </div>;
}

export default BonusSkillBar;